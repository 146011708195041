exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-balade-tsx": () => import("./../../../src/pages/balade.tsx" /* webpackChunkName: "component---src-pages-balade-tsx" */),
  "component---src-pages-guinguette-tsx": () => import("./../../../src/pages/guinguette.tsx" /* webpackChunkName: "component---src-pages-guinguette-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-information-tsx": () => import("./../../../src/pages/information.tsx" /* webpackChunkName: "component---src-pages-information-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-regulation-tsx": () => import("./../../../src/pages/regulation.tsx" /* webpackChunkName: "component---src-pages-regulation-tsx" */)
}

